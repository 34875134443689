import { Injectable, VERSION, isDevMode } from '@angular/core';
import { environment } from 'src/app/config/environments/environment';
import { AppStorageService } from 'src/app/core/app-store/app-storage.service';
import { AppRoutingService } from 'src/app/core/routing/app-routing.service';
import { AuthenUserMock } from '@config/authen.mock';
import { ErrorBase } from '@core/models/error.model';
import { AuthService } from '@features/auth/public-api';

@Injectable({ providedIn: 'root' })
export class AppForDevelopService {
  constructor(
    private readonly authService: AuthService,
    private readonly router: AppRoutingService,
    private readonly storage: AppStorageService,
  ) {}

  /**
   * Option1: Sử dụng mockdata set sẵn. Không gọi lên server để lấy các thông tin cần cho việc chứng thực: TOKEN, menu
   *
   * Option2: Sử dụng user vs pass có trong file mockdata. Gọi lên server để có TOKEN vs MENU mới nhất.
   *          Trường hợp này thường sử dụng khi đã có run service Authen ở phía server rồi.
   */
  runDevelopmentTasks = () => {
    console.group(`Angular version: ${VERSION.full}`);

    if (!isDevMode()) return;

    // Bật server mockdata lên.
    this.runMockServer();
    // Console cảnh bảo cho dev để biết các key trong storage bị trùng
    this.validateConfigLocalStorage();

    const { useMockData, autoLoginByMockUser } = environment.authen;

    // Nếu đã chứng thực rồi
    if (this.authService.isLoggedIn()) return;

    // Chạy option 1.
    if (useMockData) {
      const { menu, token, user } = AuthenUserMock.loginResponse;
      const _user: any =  {
        email: user.email,
        ho_ten: user.ho_ten,
        menu: [],
        avatar: ''
      }
      this.authService.setAuthResponseToStorage(
        token,
        _user,
        menu,
      );
      console.info(`Hi dev! Use user mock data - don't need to login`);
      return;
    }

    // option 2
    if (autoLoginByMockUser) {
      const { password, username } = AuthenUserMock.user;
      console.log(`Hi dev!>> Auto login by user: [${username}] `);
      this.authService
        .login(username, password, AuthenUserMock.user.accountType)
        .subscribe({
          next: (response: any) => {
            this.router.navigateToPreviousPage();
          },
          error: (error: ErrorBase) => {
            console.error(error);
          },
        });
    }

    console.groupEnd();
  };

  validateConfigLocalStorage = () => {
    this.storage.verifyStorageKey();
    isDevMode() && console.log(
      `Hi dev! Handle validate localStorage config key Done - your code look so good ^^`,
    )
  };

  runMockServer() {
    isDevMode() && console.log(`Hi dev! Client mock server running... ^^`);
  }
}
