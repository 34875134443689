//
export const AuthenUserMock = {
  user: {
    username: 'NNV0050588',
    password: '123qwe@..',
    accountType: 'NHAN_VIEN'
  },
  loginResponse: {
    token: 'ey...',
    user: {
      id: '12398415936892',
      image: '',
      ho_ten: 'Developer',
      email: 'Developer@gmail.com',
    },
    menu: [],
  },
  appKey: 'MOBILE_HUTECH',
};
