import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
  HttpStatusCode,
} from '@angular/common/http';
import { EMPTY, Observable, TimeoutError, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppLoadingOverlayService } from '@shared/components/app-loading-overlay/app-loading-overlay.service';
import _ from 'lodash';
import { NgbModal } from '@shared/components/lib-ng/lib-ng-bootstrap/modal/modal';
import { ToastrService } from '@shared/components/lib-ngx/ngx-toastr/toastr/toastr.service';
import { TranslocoService } from '@ngneat/transloco';

//DOC: https://angular.io/guide/http-intercept-requests-and-responses
@Injectable()
export class AppHttpResponseInterceptor implements HttpInterceptor {
  constructor(
    private readonly loadingOverlay: AppLoadingOverlayService,
    private toastr: ToastrService,
    private translate: TranslocoService,
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    /**
     * PIPE: [---> handleGlobalError ---> mappingHttpErrorResponseToErrorBase ----> ]
     */
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse | TimeoutError) => {
        if (error instanceof TimeoutError) {
          this.loadingOverlay.setState({ isLoading: false });
          return EMPTY;
        }

        this.handleHttpError(error);
        return throwError(() => error);
      })
    );
  }
  private handleHttpError = (error: HttpErrorResponse) => {
    switch (error.status) {
      case 0:
        this.toastr.error(
          this.translate.translate('error.0.message'),
          this.translate.translate('error.0.title')
        );
        return EMPTY;
      case HttpStatusCode.RequestTimeout:
        this.toastr.error(
          this.translate.translate('error.408.message'),
          this.translate.translate('error.408.title')
        );
        return EMPTY;
      case HttpStatusCode.InternalServerError:
        this.toastr.error(
          this.translate.translate('error.500.message'),
          this.translate.translate('error.500.title')
        );
        return EMPTY;
      default:
        break;
    }
    return error;
  };
}
