import * as i4 from '@angular/common';
import { DOCUMENT, CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { Component, ViewEncapsulation, ChangeDetectionStrategy, Inject, NgModule } from '@angular/core';
import * as i1 from '@taiga-ui/cdk';
import { TUI_DIALOGS, TUI_IS_MOBILE, TUI_IS_IOS, TUI_IS_ANDROID, TUI_VERSION, TuiDropdownHostModule, TuiDialogHostModule, TuiAlertHostModule, TuiScrollControlsModule } from '@taiga-ui/cdk';
import * as i5 from '@taiga-ui/core/services';
import { TuiBreakpointService } from '@taiga-ui/core/services';
import { TUI_REDUCED_MOTION, TUI_ANIMATIONS_DURATION, TUI_THEME } from '@taiga-ui/core/tokens';
import { combineLatest, of } from 'rxjs';
import { map, debounceTime } from 'rxjs/operators';
import * as i2 from '@taiga-ui/core/internal/svg-defs-host';
import { TuiSvgDefsHostModule } from '@taiga-ui/core/internal/svg-defs-host';
import * as i3 from '@taiga-ui/core/components/hints-host';
import { TuiHintsHostModule } from '@taiga-ui/core/components/hints-host';
import { EventPluginsModule } from '@tinkoff/ng-event-plugins';
function TuiRootComponent_tui_scroll_controls_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "tui-scroll-controls", 2);
  }
}
const _c0 = ["*", [["tuiOverContent"]], [["tuiOverDialogs"]], [["tuiOverAlerts"]], [["tuiOverPortals"]], [["tuiOverHints"]]];
const _c1 = ["*", "tuiOverContent", "tuiOverDialogs", "tuiOverAlerts", "tuiOverPortals", "tuiOverHints"];
class TuiRootComponent {
  constructor(reducedMotion, duration, dialogs, isMobile, breakpoint, isIOS, isAndroid, document, theme) {
    var _a;
    this.reducedMotion = reducedMotion;
    this.duration = duration;
    this.dialogs = dialogs;
    this.isMobile = isMobile;
    this.breakpoint = breakpoint;
    this.isIOS = isIOS;
    this.isAndroid = isAndroid;
    this.isMobileRes$ = this.breakpoint.pipe(map(breakpoint => breakpoint === 'mobile'));
    this.scrollbars$ = this.dialogs.length && !this.isMobile ? combineLatest([...this.dialogs]).pipe(map(dialogs => !dialogs.some(({
      length
    }) => length)), debounceTime(0)) : of(!this.isMobile);
    (_a = document.defaultView) === null || _a === void 0 ? void 0 : _a.document.body.setAttribute('data-tui-theme', theme.toLowerCase());
  }
}
TuiRootComponent.ɵfac = function TuiRootComponent_Factory(t) {
  return new (t || TuiRootComponent)(i0.ɵɵdirectiveInject(TUI_REDUCED_MOTION), i0.ɵɵdirectiveInject(TUI_ANIMATIONS_DURATION), i0.ɵɵdirectiveInject(TUI_DIALOGS), i0.ɵɵdirectiveInject(TUI_IS_MOBILE), i0.ɵɵdirectiveInject(TuiBreakpointService), i0.ɵɵdirectiveInject(TUI_IS_IOS), i0.ɵɵdirectiveInject(TUI_IS_ANDROID), i0.ɵɵdirectiveInject(DOCUMENT), i0.ɵɵdirectiveInject(TUI_THEME));
};
TuiRootComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: TuiRootComponent,
  selectors: [["tui-root"]],
  hostAttrs: ["data-tui-version", "3.69.0"],
  hostVars: 9,
  hostBindings: function TuiRootComponent_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("touchstart.passive.silent", function TuiRootComponent_touchstart_passive_silent_HostBindingHandler() {
        return 0;
      })("$.class._mobile", function TuiRootComponent___class__mobile_HostBindingHandler() {
        return ctx.isMobileRes$;
      });
    }
    if (rf & 2) {
      i0.ɵɵhostProperty("$.class._mobile", ctx.isMobileRes$);
      i0.ɵɵstyleProp("--tui-duration", ctx.duration, "ms");
      i0.ɵɵclassProp("_ios", ctx.isIOS)("_android", ctx.isAndroid)("_reduced-motion", ctx.reducedMotion);
    }
  },
  ngContentSelectors: _c1,
  decls: 14,
  vars: 3,
  consts: [["class", "t-root-scrollbar", 4, "ngIf"], [1, "t-root-content"], [1, "t-root-scrollbar"]],
  template: function TuiRootComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef(_c0);
      i0.ɵɵtemplate(0, TuiRootComponent_tui_scroll_controls_0_Template, 1, 0, "tui-scroll-controls", 0);
      i0.ɵɵpipe(1, "async");
      i0.ɵɵelement(2, "tui-svg-defs-host");
      i0.ɵɵelementStart(3, "tui-dropdown-host")(4, "div", 1);
      i0.ɵɵprojection(5);
      i0.ɵɵelementEnd();
      i0.ɵɵprojection(6, 1);
      i0.ɵɵelement(7, "tui-dialog-host");
      i0.ɵɵprojection(8, 2);
      i0.ɵɵelement(9, "tui-alert-host");
      i0.ɵɵprojection(10, 3);
      i0.ɵɵelementEnd();
      i0.ɵɵprojection(11, 4);
      i0.ɵɵelement(12, "tui-hints-host");
      i0.ɵɵprojection(13, 5);
    }
    if (rf & 2) {
      i0.ɵɵproperty("ngIf", i0.ɵɵpipeBind1(1, 1, ctx.scrollbars$));
    }
  },
  dependencies: [i1.TuiScrollControlsComponent, i2.TuiSvgDefsHostComponent, i1.TuiDropdownHostComponent, i1.TuiDialogHostComponent, i1.TuiAlertHostComponent, i3.TuiHintsHostComponent, i4.NgIf, i4.AsyncPipe],
  styles: ["@keyframes tuiPresent{to{content:\"1\"}}@keyframes tuiSkeletonVibe{to{opacity:.5}}html,.tui-zero-scrollbar{scrollbar-width:none;-ms-overflow-style:none}html::-webkit-scrollbar,.tui-zero-scrollbar::-webkit-scrollbar,html::-webkit-scrollbar-thumb,.tui-zero-scrollbar::-webkit-scrollbar-thumb{display:none}body[data-tui-theme]{font:var(--tui-font-text-s);color:var(--tui-text-01);background:var(--tui-base-01);margin:0}tui-root{position:relative;display:block;flex:1;-webkit-tap-highlight-color:transparent}tui-root>.t-root-scrollbar{position:fixed;top:0;left:0;bottom:0;right:0;display:none;margin:0}[data-tui-theme] tui-root>.t-root-scrollbar{display:block}.t-root-content{height:100%;isolation:isolate}.t-overscroll-none{overscroll-behavior:none}\n"],
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiRootComponent, [{
    type: Component,
    args: [{
      selector: 'tui-root',
      templateUrl: './root.template.html',
      styleUrls: ['./root.style.less'],
      encapsulation: ViewEncapsulation.None,
      // So that we do not force OnPush on custom dialogs
      // eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
      changeDetection: ChangeDetectionStrategy.Default,
      host: {
        'data-tui-version': TUI_VERSION,
        '[style.--tui-duration.ms]': 'duration',
        '[class._ios]': 'isIOS',
        '[class._android]': 'isAndroid',
        '[class._reduced-motion]': 'reducedMotion',
        // Required for the :active state to work in Safari. https://stackoverflow.com/a/33681490
        '(touchstart.passive.silent)': '0',
        '[$.class._mobile]': 'isMobileRes$',
        '($.class._mobile)': 'isMobileRes$'
      }
    }]
  }], function () {
    return [{
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TUI_REDUCED_MOTION]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TUI_ANIMATIONS_DURATION]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TUI_DIALOGS]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TUI_IS_MOBILE]
      }]
    }, {
      type: i5.TuiBreakpointService,
      decorators: [{
        type: Inject,
        args: [TuiBreakpointService]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TUI_IS_IOS]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TUI_IS_ANDROID]
      }]
    }, {
      type: Document,
      decorators: [{
        type: Inject,
        args: [DOCUMENT]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TUI_THEME]
      }]
    }];
  }, null);
})();
class TuiRootModule {}
TuiRootModule.ɵfac = function TuiRootModule_Factory(t) {
  return new (t || TuiRootModule)();
};
TuiRootModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TuiRootModule
});
TuiRootModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule, EventPluginsModule, TuiDropdownHostModule, TuiSvgDefsHostModule, TuiHintsHostModule, TuiDialogHostModule, TuiAlertHostModule, TuiScrollControlsModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiRootModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, EventPluginsModule, TuiDropdownHostModule, TuiSvgDefsHostModule, TuiHintsHostModule, TuiDialogHostModule, TuiAlertHostModule, TuiScrollControlsModule],
      declarations: [TuiRootComponent],
      exports: [TuiRootComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiRootComponent, TuiRootModule };
