import { AfterViewInit, Component, OnInit, VERSION, inject, isDevMode } from '@angular/core';
import { AppForDevelopService } from './share/app-for-develop/app-dev.service';
import { SmartAdminConfigService } from './share/smart-admin-config.service';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterOutlet } from '@angular/router';
import { AppLoadingModuleService } from '@shared/components/app-loading-module/app-loading-module.service';
import { AppLoadingOverlayComponent } from '@shared/components/app-loading-overlay/app-loading-overlay.component';
import { SharedModule } from '@shared/shared.module';
import { MAT_PAGINATOR_DEFAULT_OPTIONS, MatPaginatorDefaultOptions } from '@shared/components/lib-material/paginator';
import { TuiRootModule } from '@taiga-ui/core';
import { GLOBAL_CONFIG_PAGE_SIZE } from '@config/ui.const';

@Component({
  standalone: true,
  selector: 'app-root',
  imports: [
    RouterOutlet,
    SharedModule,
    AppLoadingOverlayComponent,
    TuiRootModule
  ],
  providers: [
    SmartAdminConfigService,
    {
      provide: MAT_PAGINATOR_DEFAULT_OPTIONS,
      useValue: GLOBAL_CONFIG_PAGE_SIZE as MatPaginatorDefaultOptions,
    },
  ],
  templateUrl: './app.component.html',
})

export class AppComponent implements OnInit, AfterViewInit {
  private readonly router: Router = inject(Router);
  private readonly smartAdminConfigService: SmartAdminConfigService = inject(SmartAdminConfigService);
  public readonly appLoadingModuleService: AppLoadingModuleService = inject(AppLoadingModuleService);
  private readonly appForDevelopService: AppForDevelopService = inject(AppForDevelopService);

  constructor() {
    // /**tạo hiện ứng loading khi nạp module */
    this.router.events.subscribe((event) => {
      switch (true) {
        case event instanceof NavigationStart: {
          this.appLoadingModuleService.startLoading();
          break;
        }

        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          this.appLoadingModuleService.stopLoading();
          break;
        }
        default: {
          break;
        }
      }
    });
    //...
  }

  ngOnInit() {
    if (isDevMode()) {
      console.group(`Angular ${VERSION.full} Running in [development] mode`);
      this.appForDevelopService.runDevelopmentTasks();
      console.groupEnd();
    }
  }

  ngAfterViewInit(): void {
    this.smartAdminConfigService.activeJsAppComponent()
  }
}
