import { API_VERSIONs, AuthVersion } from '@features/auth/const';
import { API_ENDPOINTS } from './api.config';
import { environment } from './environments/environment';
import { GlobalPolcies } from './policy/policy.config';
import { ICoreAuthModuleConfig } from '@features/auth/core-auth.module';

export const AUTHEN_POLICY_UEF: ICoreAuthModuleConfig = {
  application: {
    appKey: environment.authen.appKey,
    apiVersion: API_VERSIONs.VERSION_2_0_0,
    authVersion: AuthVersion.VERSION_2,
    apiVersionHeader: 'X-Api-Version',
    tokenType: 'JWT',
    authDomain: API_ENDPOINTS.auth,
    fileV2: API_ENDPOINTS.permission_v2_file_v2,
    branch: 'UEF', // ROYAL | UEF
  },

  globalPolicies: GlobalPolcies,

  defaultAuth: {
    reCaptcha: {
      enable: true,
      secretKey: environment.authen.reCapchaSecretKey,
      enableReCaptchaAfterError: 5,
    },
    typeAccount: {
      useDefault: false, // Default NHAN_VIEN - SINH_VIEN
      enable: false,
    },
    loginByUsernameAndPassword: {
      enable: true,
    },
  },

  socialNetworkAuth: {
    facebook: {
      enable: false,
      secretKey: environment.authen.facebookAuthSecretKey,
      loginOptions: {
        scope:
          'pages_messaging,pages_messaging_subscriptions,email,pages_show_list,manage_pages',
        return_scopes: true,
        enable_profile_selector: true,
      },
    },

    // Cau hinh dang nhap voi google
    google: {
      enable: false,
      loginOptions: {
        prompt: 'select_account',
        oneTapEnabled: false,
        scopes: ['email'],
      },
      clientId: environment.authen.googleAuthClientID,
    },
  },
};

export const AUTHEN_POLICY_HUTECH: ICoreAuthModuleConfig = {
  application: {
    appKey: environment.authen.appKey,
    apiVersion: API_VERSIONs.VERSION_2_0_0,
    authVersion: AuthVersion.VERSION_1,
    apiVersionHeader: 'X-Api-Version',
    tokenType: 'JWT',
    authDomain: API_ENDPOINTS.auth,
    fileV2: API_ENDPOINTS.permission_v2_file_v2,
    branch: 'HUTECH', // ROYAL | UEF
  },

  globalPolicies: GlobalPolcies,

  defaultAuth: {
    reCaptcha: {
      enable: true,
      secretKey: environment.authen.reCapchaSecretKey,
      enableReCaptchaAfterError: 5,
    },
    typeAccount: {
      useDefault: false, // Default NHAN_VIEN - SINH_VIEN
      enable: false,
    },
    loginByUsernameAndPassword: {
      enable: true,
    },
  },

  socialNetworkAuth: {
    facebook: {
      enable: false,
      secretKey: environment.authen.facebookAuthSecretKey,
      loginOptions: {
        scope:
          'pages_messaging,pages_messaging_subscriptions,email,pages_show_list,manage_pages',
        return_scopes: true,
        enable_profile_selector: true,
      },
    },

    // Cau hinh dang nhap voi google
    google: {
      enable: false,
      loginOptions: {
        prompt: 'select_account',
        oneTapEnabled: false,
        scopes: ['email'],
      },
      clientId: environment.authen.googleAuthClientID,
    },
  },
};

export const AUTHEN_POLICY_ROYAL: ICoreAuthModuleConfig = {
  application: {
    appKey: environment.authen.appKey,
    apiVersion: API_VERSIONs.VERSION_2_0_0,
    authVersion: AuthVersion.VERSION_2,
    apiVersionHeader: 'X-Api-Version',
    tokenType: 'JWT',
    authDomain: API_ENDPOINTS.auth,
    fileV2: API_ENDPOINTS.permission_v2_file_v2,
    branch: 'ROYAL', // ROYAL | UEF
  },

  globalPolicies: GlobalPolcies,

  defaultAuth: {
    reCaptcha: {
      enable: true,
      secretKey: environment.authen.reCapchaSecretKey,
      enableReCaptchaAfterError: 5,
    },
    typeAccount: {
      useDefault: false, // Default NHAN_VIEN - SINH_VIEN
      enable: false,
    },
    loginByUsernameAndPassword: {
      enable: true,
    },
  },

  socialNetworkAuth: {
    facebook: {
      enable: false,
      secretKey: environment.authen.facebookAuthSecretKey,
      loginOptions: {
        scope:
          'pages_messaging,pages_messaging_subscriptions,email,pages_show_list,manage_pages',
        return_scopes: true,
        enable_profile_selector: true,
      },
    },

    // Cau hinh dang nhap voi google
    google: {
      enable: false,
      loginOptions: {
        prompt: 'select_account',
        oneTapEnabled: false,
        scopes: ['email'],
      },
      clientId: environment.authen.googleAuthClientID,
    },
  },
};
