import { trigger, transition, style, query, animateChild } from '@angular/animations';
import * as i2 from '@angular/common';
import { DOCUMENT, CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { ChangeDetectorRef, Component, ChangeDetectionStrategy, Inject, Self, NgModule } from '@angular/core';
import * as i6 from '@angular/platform-browser';
import { Title } from '@angular/platform-browser';
import { HISTORY } from '@ng-web-apis/common';
import { TuiDestroyService } from '@taiga-ui/cdk/services';
import { TUI_IS_MOBILE, TUI_DIALOGS } from '@taiga-ui/cdk/tokens';
import { tuiCreateToken } from '@taiga-ui/cdk/utils';
import * as i5 from 'rxjs';
import { of, combineLatest } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import * as i1 from '@taiga-ui/cdk/components/scroll-controls';
import { TuiScrollControlsModule } from '@taiga-ui/cdk/components/scroll-controls';
import * as i3 from '@taiga-ui/cdk/directives';
import { TuiOverscrollModule, TuiFocusTrapModule, TuiLetModule } from '@taiga-ui/cdk/directives';
import * as i4 from '@tinkoff/ng-polymorpheus';
import { PolymorpheusModule } from '@tinkoff/ng-polymorpheus';
function TuiDialogHostComponent_section_0_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function TuiDialogHostComponent_section_0_tui_scroll_controls_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "tui-scroll-controls", 5);
  }
}
function TuiDialogHostComponent_section_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "section", 2);
    i0.ɵɵtemplate(1, TuiDialogHostComponent_section_0_ng_container_1_Template, 1, 0, "ng-container", 3)(2, TuiDialogHostComponent_section_0_tui_scroll_controls_2_Template, 1, 0, "tui-scroll-controls", 4);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const item_r2 = ctx.$implicit;
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("@host", undefined);
    i0.ɵɵattribute("aria-labelledby", item_r2.id);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("polymorpheusOutlet", item_r2.component)("polymorpheusOutletContext", item_r2);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", !ctx_r0.isMobile);
  }
}
function TuiDialogHostComponent_div_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r7 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 6);
    i0.ɵɵlistener("transitionend", function TuiDialogHostComponent_div_1_Template_div_transitionend_0_listener($event) {
      const restoredCtx = i0.ɵɵrestoreView(_r7);
      const isDialogClosesOnBack_r5 = restoredCtx.tuiLet;
      const ctx_r6 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r6.onDialog($event, !!ctx_r6.dialogs.length, !!isDialogClosesOnBack_r5));
    })("popstate", function TuiDialogHostComponent_div_1_Template_div_popstate_0_listener() {
      const restoredCtx = i0.ɵɵrestoreView(_r7);
      const isDialogClosesOnBack_r5 = restoredCtx.tuiLet;
      const ctx_r8 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r8.closeLast(ctx_r8.dialogs, !!isDialogClosesOnBack_r5));
    }, false, i0.ɵɵresolveWindow);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵclassProp("t-overlay_visible", ctx_r1.dialogs.length);
  }
}
const FAKE_HISTORY_STATE = {
  label: 'ignoreMe'
};
const isFakeHistoryState = historyState => (historyState === null || historyState === void 0 ? void 0 : historyState.label) === FAKE_HISTORY_STATE.label;
/**
 * Is closing dialog on browser backward navigation enabled
 */
const TUI_DIALOG_CLOSES_ON_BACK = tuiCreateToken(of(false));
class TuiDialogHostComponent {
  constructor(isMobile, isDialogClosesOnBack$, dialogsByType, historyRef, titleService, destroy$, cdr, doc) {
    this.isMobile = isMobile;
    this.isDialogClosesOnBack$ = isDialogClosesOnBack$;
    this.dialogsByType = dialogsByType;
    this.historyRef = historyRef;
    this.titleService = titleService;
    this.destroy$ = destroy$;
    this.cdr = cdr;
    this.doc = doc;
    this.dialogs = [];
  }
  ngOnInit() {
    // Due to this view being parallel to app content, `markForCheck` from `async` pipe
    // can happen after view was checked, so calling `detectChanges` instead
    combineLatest(this.dialogsByType).pipe(map(arr => [].concat(...arr).sort((a, b) => a.createdAt - b.createdAt)), takeUntil(this.destroy$)).subscribe(dialogs => {
      var _a;
      this.dialogs = dialogs;
      this.cdr.markForCheck();
      (_a = this.doc.defaultView) === null || _a === void 0 ? void 0 : _a.document.documentElement.classList.toggle('t-overscroll-none', !!dialogs.length);
    });
  }
  closeLast(dialogs, isDialogClosesOnBack) {
    if (!isDialogClosesOnBack) {
      return;
    }
    const [last] = dialogs.slice(-1);
    if (!last) {
      return;
    }
    if (dialogs.length > 1) {
      this.historyRef.pushState(FAKE_HISTORY_STATE, this.titleService.getTitle());
    }
    last.$implicit.complete();
  }
  onDialog({
    propertyName
  }, popupOpened, isDialogClosesOnBack) {
    if (!isDialogClosesOnBack || propertyName !== 'letter-spacing') {
      return;
    }
    if (popupOpened) {
      this.historyRef.pushState(FAKE_HISTORY_STATE, this.titleService.getTitle());
    } else if (isFakeHistoryState(this.historyRef.state)) {
      this.historyRef.back();
    }
  }
}
TuiDialogHostComponent.ɵfac = function TuiDialogHostComponent_Factory(t) {
  return new (t || TuiDialogHostComponent)(i0.ɵɵdirectiveInject(TUI_IS_MOBILE), i0.ɵɵdirectiveInject(TUI_DIALOG_CLOSES_ON_BACK), i0.ɵɵdirectiveInject(TUI_DIALOGS), i0.ɵɵdirectiveInject(HISTORY), i0.ɵɵdirectiveInject(Title), i0.ɵɵdirectiveInject(TuiDestroyService, 2), i0.ɵɵdirectiveInject(ChangeDetectorRef), i0.ɵɵdirectiveInject(DOCUMENT));
};
TuiDialogHostComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: TuiDialogHostComponent,
  selectors: [["tui-dialog-host"]],
  features: [i0.ɵɵProvidersFeature([TuiDestroyService])],
  decls: 3,
  vars: 4,
  consts: [["aria-modal", "true", "role", "dialog", "tuiFocusTrap", "", "tuiOverscroll", "all", "tuiScrollRef", "", "class", "t-dialog", 4, "ngFor", "ngForOf"], ["class", "t-overlay", 3, "t-overlay_visible", "transitionend", "popstate", 4, "tuiLet"], ["aria-modal", "true", "role", "dialog", "tuiFocusTrap", "", "tuiOverscroll", "all", "tuiScrollRef", "", 1, "t-dialog"], [4, "polymorpheusOutlet", "polymorpheusOutletContext"], ["class", "t-scrollbars", 4, "ngIf"], [1, "t-scrollbars"], [1, "t-overlay", 3, "transitionend", "popstate"]],
  template: function TuiDialogHostComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, TuiDialogHostComponent_section_0_Template, 3, 5, "section", 0)(1, TuiDialogHostComponent_div_1_Template, 1, 2, "div", 1);
      i0.ɵɵpipe(2, "async");
    }
    if (rf & 2) {
      i0.ɵɵproperty("ngForOf", ctx.dialogs);
      i0.ɵɵadvance(1);
      i0.ɵɵproperty("tuiLet", i0.ɵɵpipeBind1(2, 2, ctx.isDialogClosesOnBack$));
    }
  },
  dependencies: [i1.TuiScrollControlsComponent, i2.NgForOf, i3.TuiFocusTrapDirective, i3.TuiOverscrollDirective, i1.TuiScrollRefDirective, i4.PolymorpheusOutletDirective, i2.NgIf, i3.TuiLetDirective, i2.AsyncPipe],
  styles: ["[_nghost-%COMP%]{position:fixed;left:0;bottom:0;width:100%;height:0}.t-overlay[_ngcontent-%COMP%], .t-dialog[_ngcontent-%COMP%]{position:fixed;top:0;left:0;right:0;bottom:0;display:flex;align-items:flex-start;outline:none;overflow:auto;scrollbar-width:none;-ms-overflow-style:none}.t-overlay.ng-animating[_ngcontent-%COMP%], .t-dialog.ng-animating[_ngcontent-%COMP%]{overflow:clip}.t-overlay[_ngcontent-%COMP%]::-webkit-scrollbar, .t-dialog[_ngcontent-%COMP%]::-webkit-scrollbar, .t-overlay[_ngcontent-%COMP%]::-webkit-scrollbar-thumb, .t-dialog[_ngcontent-%COMP%]::-webkit-scrollbar-thumb{background:transparent;width:0;height:0}.t-dialog[_ngcontent-%COMP%]{bottom:auto;height:100%}.t-overlay[_ngcontent-%COMP%]{height:100%;pointer-events:none;touch-action:none;opacity:0;letter-spacing:normal;transition:opacity var(--tui-duration, .3s),letter-spacing .01s;background:rgba(0,0,0,.75);-webkit-backdrop-filter:var(--tui-backdrop, none);backdrop-filter:var(--tui-backdrop, none)}.t-overlay_visible[_ngcontent-%COMP%]{opacity:1;letter-spacing:1px}.t-dialog[_ngcontent-%COMP%]:last-of-type{z-index:1}.t-scrollbars[_ngcontent-%COMP%]{position:fixed;top:0;left:0;right:0;bottom:0;margin:0;color:#747474}"],
  data: {
    animation: [trigger('host', [transition(':enter', [style({
      overflow: 'clip'
    }), query(':scope > *', [animateChild()], {
      optional: true
    })]), transition(':leave', [style({
      overflow: 'clip'
    }), query(':scope > *', [animateChild()], {
      optional: true
    })])])]
  }
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiDialogHostComponent, [{
    type: Component,
    args: [{
      selector: 'tui-dialog-host',
      templateUrl: './dialog-host.template.html',
      styleUrls: ['./dialog-host.style.less'],
      // So that we do not force OnPush on custom dialogs
      // eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
      changeDetection: ChangeDetectionStrategy.Default,
      providers: [TuiDestroyService],
      animations: [trigger('host', [transition(':enter', [style({
        overflow: 'clip'
      }), query(':scope > *', [animateChild()], {
        optional: true
      })]), transition(':leave', [style({
        overflow: 'clip'
      }), query(':scope > *', [animateChild()], {
        optional: true
      })])])]
    }]
  }], function () {
    return [{
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TUI_IS_MOBILE]
      }]
    }, {
      type: i5.Observable,
      decorators: [{
        type: Inject,
        args: [TUI_DIALOG_CLOSES_ON_BACK]
      }]
    }, {
      type: Array,
      decorators: [{
        type: Inject,
        args: [TUI_DIALOGS]
      }]
    }, {
      type: History,
      decorators: [{
        type: Inject,
        args: [HISTORY]
      }]
    }, {
      type: i6.Title,
      decorators: [{
        type: Inject,
        args: [Title]
      }]
    }, {
      type: i5.Observable,
      decorators: [{
        type: Self
      }, {
        type: Inject,
        args: [TuiDestroyService]
      }]
    }, {
      type: i0.ChangeDetectorRef,
      decorators: [{
        type: Inject,
        args: [ChangeDetectorRef]
      }]
    }, {
      type: Document,
      decorators: [{
        type: Inject,
        args: [DOCUMENT]
      }]
    }];
  }, null);
})();
class TuiDialogHostModule {}
TuiDialogHostModule.ɵfac = function TuiDialogHostModule_Factory(t) {
  return new (t || TuiDialogHostModule)();
};
TuiDialogHostModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TuiDialogHostModule
});
TuiDialogHostModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule, PolymorpheusModule, TuiOverscrollModule, TuiFocusTrapModule, TuiLetModule, TuiScrollControlsModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiDialogHostModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, PolymorpheusModule, TuiOverscrollModule, TuiFocusTrapModule, TuiLetModule, TuiScrollControlsModule],
      declarations: [TuiDialogHostComponent],
      exports: [TuiDialogHostComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TUI_DIALOG_CLOSES_ON_BACK, TuiDialogHostComponent, TuiDialogHostModule };
