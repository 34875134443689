import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { importProvidersFrom, isDevMode } from '@angular/core';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { AUTHEN_POLICY_HUTECH } from '@config/authen.config';
import { AppHttpResponseInterceptor } from '@core/interceptors/app-response.interceptor';
import { CoreAuthModule } from '@features/auth/public-api';
import { ToastrModule } from '@shared/components/lib-ngx/ngx-toastr/toastr/toastr.module';
import { TranslocoRootModule } from '@shared/components/zaa-transloco-root/transloco-root.module';
import { TuiRootModule } from '@taiga-ui/core';
import { AppComponent } from './app/app.component';
import { AppRoutingModule } from './app/app.routing.module';

const APP_BOOTSTRAP_CONFIG = {
  providers: [
    importProvidersFrom([
      // Core lib modules
      BrowserModule,
      HttpClientModule,
      AppRoutingModule,

      CoreAuthModule.forRoot(AUTHEN_POLICY_HUTECH),

      // Global modules
      TranslocoRootModule,
      ToastrModule.forRoot({ timeOut: 3000 }),
      TuiRootModule,
    ]),
    provideAnimations(),
    // Interceptor http provider
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppHttpResponseInterceptor,
      multi: true,
    },
  ],
};

bootstrapApplication(AppComponent, APP_BOOTSTRAP_CONFIG)
  .then(() => {
    if (isDevMode()) {
      console.group(`Bootstrap Angular application success`);
    }
  })
  .catch((err) => {
    console.error(`Error when bootstrap application: ${err.message}`);
  });
