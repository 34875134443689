<div *ngIf="viewModel$ |async as viewModel">
  <div *ngIf="viewModel.isLoading" class="container d-flex justify-content-center align-items-center m-auto spinner"
    [@fadeInOut]>
    <div class="spinner-grow text-primary spinner-grow-sm m-1" role="status"></div>
    <div class="spinner-grow text-secondary spinner-grow-sm m-1" role="status"></div>
    <div class="spinner-grow text-success spinner-grow-sm m-1" role="status"></div>
  </div>
  <div *ngIf="viewModel.isLoading" class="background" [@fadeInOut]>
  </div>

</div>
