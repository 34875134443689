import { FullscreenOverlayContainer, OverlayContainer, OverlayModule } from '@angular/cdk/overlay';
import { Component, OnInit } from '@angular/core';
import { AppLoadingOverlayService, LoadingSharedState } from './app-loading-overlay.service';
import { CoreReactiveState } from '@core/state/observable-state';
import { CommonModule } from '@angular/common';
import { environment } from '@config/environments/environment';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Observable, delay, map, of, switchMap, tap, timer } from 'rxjs';
export type LoadingComponentState = {
  isLoading: boolean;
  triggerTimeout: boolean
}
export type viewModel$ = Pick<LoadingComponentState, 'isLoading'>;
@Component({
  standalone: true,
  imports: [CommonModule, OverlayModule,],
  providers: [
    { provide: OverlayContainer, useClass: FullscreenOverlayContainer },
  ],
  selector: 'app-loading-overlay',
  templateUrl: './app-loading-overlay.component.html',
  styleUrls: ['./app-loading-overlay.component.css'],
  animations: [
    trigger('fadeInOut', [
      state('void', style({
        opacity: 0
      })),
      transition('void <=> *', animate(300)),
    ])
  ]
})
export class AppLoadingOverlayComponent extends CoreReactiveState<LoadingComponentState> implements OnInit {
  constructor(private readonly appLoadingOverlayService: AppLoadingOverlayService) {
    super();
  }

  // Thời gian nhiều nhất mà cái loading này tồn tại, "Không có gì là mãi mãi cho đến khi "Timeout""
  private getTimeOutFromEnvironment = () => {
    return environment.application.http.timeout ?? 30 * 1000; // default 1s;
  }

  ngOnInit() {
    this.initialize({
      isLoading: false,
      triggerTimeout: false,
    });
    this.connect(
      {
        isLoading: this.appLoadingOverlayService.getState().pipe(
          map(state => state?.isLoading ?? false),
        ),
        triggerTimeout: this.appLoadingOverlayService.getState().pipe(
          map(state => state?.isLoading ?? false),
          switchMap((state) => {
            if (state) {
              return timer(this.getTimeOutFromEnvironment()).pipe(
                map(() => {
                  this.patch({ isLoading: false });
                  return false;
                })
              );
            } else {
              return of(false);
            }
          })

        )
      }
    );
  }

  viewModel$: Observable<LoadingSharedState> = this.onlySelectWhen(['isLoading']);
}
